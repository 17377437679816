import React, { useState, useEffect } from "react";
import TeachersNav from "./TeachersNav.js";
import TeachersSideBar from "./TeachersSideBar.js";
import TeachersFooter from "./TeachersFooter.js";
import SmallNav from "./SmallNav.js";
import useRedirectLoggedOutTutor from "../../customHook/useRedirectLoggedOutTutor.js";
import { useDispatch } from "react-redux";
import { SET_LOGIN } from "../../redux/auth/authSlice.js";
import { getLoginStatus } from "../../services/authServices.js";

const TeachersLayout = ({ children }) => {
  useRedirectLoggedOutTutor("/login");
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const dispatch = useDispatch();

  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();

      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <>
      <TeachersNav />
      <SmallNav />
      <div className="w-full h-[100%] border-l-2 border-[#A7AEB91A] flex  ">
        <TeachersSideBar setActiveComponent={handleSetActiveComponent} />
        <div className="pb-24 md:pb-8 bg-[#FAFAFA] w-full flex-col pl-0 lg:px-4 ml-0 md:ml-[180px]">
          {children}
        </div>
      </div>
    </>
  );
};

export default TeachersLayout;
