import React, { useState, useEffect } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import RegisterStudent from "./RegisterStudent";
import { fetchCohorts, fetchStudentStatistics } from "../../../services/cohortService"; // Adjust the path as needed

const Cohort = () => {
  const [selectedCourse, setSelectedCourse] = useState("All");
  const [cohortData, setCohortData] = useState({ name: "All", noOfStudents: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCohortId, setCurrentCohortId] = useState(null);
  const [cohorts, setCohorts] = useState([]);

  const cohortOptions = ["All", "BA", "DA"];

  // Fetch cohorts on component mount
  useEffect(() => {
    const initializeCohorts = async () => {
      try {
        const cohortList = await fetchCohorts();
        if (cohortList.length > 0) setCurrentCohortId(cohortList[0].id);
        setCohorts(cohortList);
      } catch (error) {
        console.error("Error initializing cohorts:", error);
      }
    };
    initializeCohorts();
  }, []);

  // Fetch student statistics whenever the current cohort or selected course changes
  useEffect(() => {
    const fetchAndSetStatistics = async () => {
      if (currentCohortId) {
        try {
          const stats = await fetchStudentStatistics(currentCohortId);
          const { totalStudentsCount, businessAnalysisCount, dataAnalysisCount } = stats;

          const updatedCohortData = {
            name: selectedCourse === "BA" ? "Business Analysis" : selectedCourse === "DA" ? "Data Analysis" : "All",
            noOfStudents:
              selectedCourse === "BA" ? businessAnalysisCount :
                selectedCourse === "DA" ? dataAnalysisCount : totalStudentsCount || 0,
          };

          setCohortData(updatedCohortData);
        } catch (error) {
          console.error("Error fetching student statistics:", error);
        }
      }
    };

    fetchAndSetStatistics();
  }, [currentCohortId, selectedCourse]);

  const handleSelectRadioButton = (event) => setSelectedCourse(event.target.value);

  return (
    <div className="flex flex-col px-4 py-5 bg-white rounded-lg shadow-lg max-md:px-5 w-full">
      <p className="flex gap-2.5 text-xs justify-center p-2.5 text-white rounded bg-teal-950">
        {"Current Cohort: "}
        {cohorts.length > 0 && currentCohortId ? (
          <>
            {new Date(cohorts.find((cohort) => cohort.id === currentCohortId)?.start_date).toLocaleString("default", { month: "long" })}
            {" "}
            {new Date(cohorts.find((cohort) => cohort.id === currentCohortId)?.start_date).getFullYear()}
          </>
        ) : (
          "N/A"
        )}
      </p>

      <fieldset className="mt-8 w-full text-sm text-neutral-500">
        <div className="flex gap-5 justify-between max-md:flex-col">
          {cohortOptions.map((option) => (
            <label key={option} className="flex items-center gap-1.5 cursor-pointer">
              <input
                type="radio"
                name="course"
                value={option}
                checked={selectedCourse === option}
                onChange={handleSelectRadioButton}
                className="shrink-0 w-3.5 h-3.5 rounded-full border-2 border-solid border-zinc-400"
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      </fieldset>

      <div className="flex flex-col md:flex-row justify-between p-5 mt-4 bg-white rounded-lg border border-solid border-zinc-400 items-center">
        <div className="flex flex-col">
          <span className="text-lg text-neutral-500">No of Students</span>
          <span className="mt-2.5 text-2xl font-medium text-teal-950">
            {cohortData.noOfStudents || 0}
          </span>
        </div>

        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/88463c0eac0d2aebf5f9c97b5a990e08297df268b8472bb5a45224d7795bd257?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          alt="Register Student"
          className="shrink-0 my-auto w-10 aspect-square"
        />
      </div>

      <p onClick={() => setIsModalOpen(true)} className="self-center cursor-pointer mt-5 text-sm text-sky-600">
        Register new student
      </p>
      <CustomModal isOpen={isModalOpen}>
        <RegisterStudent closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default Cohort;
