import React, { useState, createContext, useContext, useEffect } from "react";
import StudentNav from "./StudentNav.js";
import StudentSideBar from "./StudentSideBar.js";
import StudentFooter from "./StudentFooter.js";
import SmallNav from "./SmallNav.js";
import { useDispatch } from "react-redux";
import { SET_LOGIN } from "../../redux/auth/authSlice.js";
import { getLoginStatus } from "../../services/authServices.js";

const ActiveComponentContext = createContext(null);

const StudentLayout = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  const dispatch = useDispatch();

  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();

      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  const handleSetActiveComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  return (
    <ActiveComponentContext.Provider
      value={{ activeComponent, setActiveComponent }}>
      <div className="min-h-screen flex flex-col">
        {" "}
        {/* Full-height layout */}
        <StudentNav />
        <SmallNav />
        <main className="flex-grow flex bg-[#FAFAFA]">
          {" "}
          {/* Main content area takes up remaining space */}
          <div className="hidden md:block border-r border-[#A7AEB91A] fixed left-0 h-screen">
            {" "}
            {/* Hide sidebar on small screens */}
            <StudentSideBar />
          </div>
          <div className="w-full px-0 mb-20 py-6 lg:px-10 ml-0 md:ml-[200px]">
            {" "}
            {/* Content padding */}
            {children}
          </div>
        </main>
        <StudentFooter />
      </div>
    </ActiveComponentContext.Provider>
  );
};

export default StudentLayout;
