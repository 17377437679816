import React, { useState } from "react";
import CustomModal from "../../StudentsDashboard/Modal";
import SubmitSuccess from "../../StudentsDashboard/Assignment/SubmitSuccess";
import { scheduleClass } from "../../../services/classServices";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { formatTimeTo12Hour } from "../../../utils/convertDate";
import { useNavigate } from "react-router-dom";

const initialData = {
  classTitle: "",
  classLink: "",
  description: "",
  classDate: "",
  startTime: "",
  endTime: "",
};
const ScheduleClass = ({ closeModal }) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const { classTitle, classLink, classDate, description, startTime, endTime } =
    formData;
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formattedDate = formatDate(classDate);

  const text = "Class successfully scheduled!";
  const desc = `You have successfully scheduled a class for ${formattedDate}, from ${formatTimeTo12Hour(
    startTime
  )} - ${formatTimeTo12Hour(endTime)}`;
  const buttonText = "Go to classes";
  const buttonLink = "/teachersdashboard/classes";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!classDate || !startTime || !classTitle || !endTime || !description) {
      return toast.error("All fields are required.");
    }
    // Validate date and time
    const parsedClassDate = new Date(classDate);
    const parsedStartTime = new Date(`${classDate}T${startTime}`);
    const parsedEndTime = new Date(`${classDate}T${endTime}`);

    const now = new Date();

    if (
      isNaN(parsedClassDate.getTime()) ||
      isNaN(parsedStartTime.getTime()) ||
      isNaN(parsedEndTime.getTime())
    ) {
      return toast.error("Invalid date or time format");
    }


    if (parsedStartTime >= parsedEndTime) {
      return toast.error("Start time must be before end time");
    }

    const classData = {
      classTitle,
      classLink,
      classDate,
      description,
      startTime,
      endTime,
    };
    setIsLoading(true);
    try {
      const response = await scheduleClass(classData);
      if (response.status >= 200 && response.status < 300) {
        setIsLoading(false);
        setIsSuccessOpen(true);
      }
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = "Session expired. Please log in again.";
          //navigate("/login");
        } else if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
      }
      setIsLoading(false);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessButtonClick = (link) => {
    navigate(link);
    closeModal();
  };

  return (
    <>
    {isSuccessOpen ? (
      <div className="min-h-[450px]">
          <CustomModal
        isOpen={isSuccessOpen}
        closeModal={() => setIsSuccessOpen(false)}>
        <SubmitSuccess
          text={text}
          desc={desc}
          buttonText={buttonText}
          link={buttonLink}
          closeModal={() => setIsSuccessOpen(false)}
          handleButtonClick={() =>
            handleSuccessButtonClick("/teachersdashboard/")
          }
        />
      </CustomModal>
      </div>
    ) : (
      <form className="flex flex-col pt-5 px-10 py-6 font-roboto pb-17 text-base bg-white rounded-lg max-w-[730px] text-neutral-500 max-md:pl-5">
        <div className="flex gap-5 items-start text-xl font-medium text-teal-950 max-md:flex-wrap max-md:max-w-full">
          <h3 className="flex-auto mt-1">Schedule Class</h3>
          <img
            onClick={closeModal}
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 w-6 aspect-square"
          />
        </div>
        <label className="mt-4 text-lg text-teal-950 max-md:max-w-full">
          Class title
        </label>
        <input
          name="classTitle"
          value={classTitle}
          onChange={handleInputChange}
          className="justify-center items-start px-5 py-2 mt-1 rounded-md border border-solid border-zinc-400 max-md:max-w-full"
          placeholder="Enter the topic of the class"
        />
        <label className="mt-4 text-lg text-teal-950 max-md:max-w-full">
          Class link
        </label>
        <input
          name="classLink"
          value={classLink}
          onChange={handleInputChange}
          className="justify-center items-start px-5 py-2 mt-1  rounded-md border border-solid border-zinc-400 max-md:max-w-full"
          placeholder="Class virtual meeting link"
        />
        <label className="mt-4 text-lg text-teal-950 max-md:max-w-full">
          Class duration
        </label>
        <div className="self-start mt-2">Date</div>
        <div className="flex gap-2.5 justify-center self-start px-5 py-2 mt-1.5 bg-sky-100 rounded-3xl">
          <input
            placeholder="Select Date"
            type="date"
            name="classDate"
            value={classDate}
            onChange={handleInputChange}
            className="w-full h-full outline-none border-none bg-sky-100"
          />
        </div>
        <div className="mt-4 max-md:max-w-full">Time</div>
        <div className="flex gap-5 mt-1.5 whitespace-nowrap max-md:flex-wrap">
          <div className="flex gap-2.5 justify-between px-5 py-2 items-center rounded-md border border-solid border-zinc-400">
            <div>From</div>
            <input
              name="startTime"
              type="time"
              className="w-full h-full outline-none border-none"
              value={startTime}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex gap-2.5 justify-between px-5 py-2 items-center rounded-md border border-solid border-zinc-400">
            <div>To</div>
            <input
              type="time"
              className="w-full h-full outline-none border-none"
              value={endTime}
              name="endTime"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <label className="mt-4 text-lg text-teal-950 max-md:max-w-full">
          Description (optional)
        </label>
        <textarea
          name="description"
          value={description}
          onChange={handleInputChange}
          className="justify-center items-start  px-5 py-4 mt-1 rounded-md border border-solid border-zinc-400 max-md:max-w-full"
          placeholder="Provide description of class content"
        />
        <button
          onClick={handleSubmit}
          className="justify-center text-center items-center px-5 py-2 mt-8 text-lg font-medium text-white bg-sky-600 rounded-md max-md:max-w-full">
          {isLoading ? (
            <div className="inset-0 flex items-center justify-center">
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            "Schedule class"
          )}
        </button>
      </form>
      )}
    
    </>
  );
};

export default ScheduleClass;
