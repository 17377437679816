// App.js

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Register from "./components/Register";
import Ba from "./components/Ba";
import Da from "./components/Da";
import Faqdtl from "./components/Faqdtl";
import Home from "./components/Home";
import ScrollToTop from "./components/ScrollToTop";
import AdminLogin from "./components/AdminLogin";
import TutorLogin from "./components/TutorLogin";
import StudentLayout from "./components/StudentsDashboard/StudentLayout";
import Dashboard from "./components/StudentsDashboard/Dashboard/Dashboard";
import Classes from "./components/StudentsDashboard/Classes/Classes";
import StudentAssignment from "./components/StudentsDashboard/Assignment/StudentAssignment";
import Projects from "./components/StudentsDashboard/Project/NotSubmittedProject.js";
import Explore from "./components/StudentsDashboard/Explore/Explore";
import CourseMaterials from "./components/StudentsDashboard/CourseMaterials.js";
import Bacd from "./components/StudentsDashboard/Classes/Bacd";
import ReviewedAssignment from "./components/StudentsDashboard/Assignment/ReviewedAssignment";
import NotSubmitted from "./components/StudentsDashboard/Assignment/NotSubmitted";
import { ToastContainer } from "react-toastify";
import TeachersLayout from "./components/TeachersDashboard/TeachersLayout";
import TeachersDashboard from "./components/TeachersDashboard/TeachersDashboard";
import TeachersAssignment from "./components/TeachersDashboard/Assignment/Assignment";
import TeachersClasses from "./components/TeachersDashboard/Classes/Classes";
import "react-toastify/dist/ReactToastify.css";
import TeachersAssignmentList from "./components/TeachersDashboard/Assignment/TeachersAssignmentList.js";
import UpadateReviewedAssignment from "./components/TeachersDashboard/Assignment/UpdateReviewedAssignment";
import NoProject from "./components/TeachersDashboard/Project/NoProject";
import ProjectPreview from "./components/TeachersDashboard/Project/ProjectPreview";
import ProjectSubmissions from "./components/TeachersDashboard/Project/ProjectSubmissions.js";
import SubmissionDetails from "./components/TeachersDashboard/Project/SubmissionDetails.js";
import ReviewSubmission from "./components/TeachersDashboard/Assignment/ReviewSubmission.js";
import AssignmentPreview from "./components/TeachersDashboard/Assignment/AssignmentPreview.js";
import AdminLayout from "./components/Admindashboard1/AdminLayout.js";
import Admindashboard from "./components/Admindashboard1/Dashboard/Admindashboard1.js";
import CourseList from "./components/Admindashboard1/courses/CourseList.js";
import Updates from "./components/Admindashboard1/updates/Updates.js";
import Faqs from "./components/Admindashboard1/updates/Faqs.js";
import Testimonials from "./components/Admindashboard1/updates/Testimonials.js";
import LearningPlatform from "./components/Admindashboard1/learning platform/LearningPlatform.js";
import AddCourse from "./components/Admindashboard1/courses/AddCourse.js";
import Coursedetails from "./components/Admindashboard1/courses/Coursedetails.js";
import VideoList from "./components/Admindashboard1/learning platform/VideosList.js";
import Payment1 from "./components/Payments/Payment1.js";
import PaymentSuccess from "./components/Payments/PaymentSuccess.js";
import PaymentFailed from "./components/Payments/PaymentFailed.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCardForm from "./components/Payments/StripeCardForm.js";
import AboutUs1 from "./components/AboutUs";
import { useSelector } from "react-redux";
import { selectStripeSecret } from "./redux/auth/authSlice.js";
import StudentProfile from "./components/StudentsDashboard/StudentProfile.js";
import UploadProfilePicture from "./components/StudentsDashboard/UploadProfilePicture.js";
import SubmittedAssignment from "./components/StudentsDashboard/Assignment/SubmitedAssignment.js";
import AboutPage from "./components/AboutUs/page/AboutPage.js";
import ContactPage from "./components/ContactUs/ContactPage";
import ProjectsPage from "./components/StudentsDashboard/Project/ProjectsPage.js";
import NotSubmittedProject from "./components/StudentsDashboard/Project/NotSubmittedProject.js";
import YourSubmission from "./components/StudentsDashboard/Project/YourSubmission.js";
import ReviewedProject from "./components/StudentsDashboard/Project/ReviewedProject.js";
import TeachersProject from "./components/TeachersDashboard/Project/TeachersProjects.js";
import SecondPaymentSuccess from "./components/Payments/SecondPaymentSuccess.js";
import StripePaymentSuccess from "./components/Payments/StripePaymentSuccess.js";
import SubmittedProject from "./components/StudentsDashboard/Project/SubmittedProject.js";
import ProjectDetails from "./components/TeachersDashboard/Project/ProjectDetails.js";
import ReviewProjectSubmission from "./components/TeachersDashboard/Project/ReviewProject.js";
import TutorProfile from "./components/TeachersDashboard/TutorProfile.js";
import ChangeEmail from "./components/ChangeEmail.js";
import EmailSuccess from "./components/EmailSuccess.js";
import ManageCohortsPage from "./components/Admindashboard1/Manage cohort/ManageCohort.js";
import VideoListPage from "./components/StudentsDashboard/Dashboard/VideoListPage.js";

// Layout with Navbar
const MainLayout = ({ children }) => (
  <>
    <Navbar />
    {children}
  </>
);

// Layout without Navbar
const AuthLayout = ({ children }) => <>{children}</>;

function App() {
  const clientSecret = useSelector(selectStripeSecret);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ); // Load Stripe.js
  const dispatch = useDispatch();


  return (
    <Router>
      <div>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />

          <Route
            path="/contact-us"
            element={
              <MainLayout>
                <ContactPage />
              </MainLayout>
            }
          />

          <Route
            path="/about-us"
            element={
              <MainLayout>
                <AboutPage />
              </MainLayout>
            }
          />

          <Route
            path="/register"
            element={
              <AuthLayout>
                <Register />
              </AuthLayout>
            }
          />
          <Route
            path="/login"
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
          <Route
            path="/business-analysis"
            element={
              <MainLayout>
                <Ba />
              </MainLayout>
            }
          />
          <Route
            path="/data-analysis"
            element={
              <MainLayout>
                <Da />
              </MainLayout>
            }
          />
          <Route
            path="/faqdtl"
            element={
              <MainLayout>
                <Faqdtl />
              </MainLayout>
            }
          />

          <Route
            path="/adminlogin"
            element={
              <AuthLayout>
                <AdminLogin />
              </AuthLayout>
            }
          />
          <Route
            path="/studentdashboard"
            element={
              <StudentLayout>
                <Dashboard />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/profile"
            element={
              <StudentLayout>
                <StudentProfile />
              </StudentLayout>
            }
          />

          <Route
            path="/studentdashboard/profile/upload"
            element={
              <StudentLayout>
                <UploadProfilePicture />
              </StudentLayout>
            }
          />

          <Route
            path="/teachersdashboard/profile/upload"
            element={
              <TeachersLayout>
                <UploadProfilePicture />
              </TeachersLayout>
            }
          />

          <Route
            path="/admindashboard/profile/upload"
            element={
              <AdminLayout>
                <UploadProfilePicture />
              </AdminLayout>
            }
          />

          <Route
            path="/studentdashboard/course_document"
            element={
              <StudentLayout>
                <Bacd />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/classes"
            element={
              <StudentLayout>
                <Classes />
              </StudentLayout>
            }
          />

          <Route
            path="/studentdashboard/classes/course_materials"
            element={
              <StudentLayout>
                <CourseMaterials />
              </StudentLayout>
            }
          />

          <Route
            path="/studentdashboard/assignment"
            element={
              <StudentLayout>
                <StudentAssignment />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/assignment/reviewed/:id"
            element={
              <StudentLayout>
                <ReviewedAssignment />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/assignment/submitted/:id"
            element={
              <StudentLayout>
                <SubmittedAssignment />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/assignment/Not submitted/:id"
            element={
              <StudentLayout>
                <NotSubmitted />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/project"
            element={
              <StudentLayout>
                <ProjectsPage />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/project/Not submitted/:id"
            element={
              <StudentLayout>
                <NotSubmittedProject />
              </StudentLayout>
            }
          />

          <Route
            path="/studentdashboard/project/submitted/:id"
            element={
              <StudentLayout>
                <SubmittedProject />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/project/submitted/view/:id"
            element={
              <StudentLayout>
                <YourSubmission />
              </StudentLayout>
            }
          />

          <Route
            path="/studentdashboard/project/reviewed/:id"
            element={
              <StudentLayout>
                <ReviewedProject />
              </StudentLayout>
            }
          />
          <Route
            path="/studentDashboard/classes/videos"
            element={
              <StudentLayout>
                <VideoListPage />
              </StudentLayout>
            }
          />
          <Route
            path="/teachersdashboard/project/reviewed/:id"
            element={
              <TeachersLayout>
                <ReviewedProject />
              </TeachersLayout>
            }
          />
          <Route
            path="/studentdashboard/explore"
            element={
              <StudentLayout>
                <Explore />
              </StudentLayout>
            }
          />
          <Route
            path="/studentdashboard/course_materials"
            element={
              <StudentLayout>
                <CourseMaterials />
              </StudentLayout>
            }
          />
          <Route
            path="/tutorlogin"
            element={
              <AuthLayout>
                <TutorLogin />
              </AuthLayout>
            }
          />

          {/* Teachers Routes */}
          <Route
            path="/teachersdashboard"
            element={
              <TeachersLayout>
                <TeachersDashboard />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/course_materials"
            element={
              <TeachersLayout>
                <CourseMaterials />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/profile"
            element={
              <TeachersLayout>
                <TutorProfile />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/classes"
            element={
              <TeachersLayout>
                <TeachersClasses />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/assignment"
            element={
              <TeachersLayout>
                <TeachersAssignment />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/assignment/list/:name"
            element={
              <TeachersLayout>
                <TeachersAssignmentList />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/assignment/preview"
            element={
              <TeachersLayout>
                <AssignmentPreview />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/assignment/review/:id"
            element={
              <TeachersLayout>
                <ReviewSubmission />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/assignment/updatereview/:id"
            element={
              <TeachersLayout>
                <UpadateReviewedAssignment />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/project"
            element={
              <TeachersLayout>
                <TeachersProject />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/project/details/:id"
            element={
              <TeachersLayout>
                <ProjectDetails />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/project/list/:id"
            element={
              <TeachersLayout>
                <ProjectSubmissions />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/project/submissions/review/"
            element={
              <TeachersLayout>
                <ReviewProjectSubmission />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/project/submissions/view/"
            element={
              <TeachersLayout>
                <SubmissionDetails />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/project/preview"
            element={
              <TeachersLayout>
                <ProjectPreview />
              </TeachersLayout>
            }
          />

          <Route
            path="/teachersdashboard/project/submissions/view/:id"
            element={
              <TeachersLayout>
                <SubmissionDetails />
              </TeachersLayout>
            }
          />
          <Route
            path="/teachersdashboard/project/submissions/review/:id"
            element={
              <TeachersLayout>
                <ReviewedProject />
              </TeachersLayout>
            }
          />
          <Route
            path="/admindashboard"
            element={
              <AdminLayout>
                <Admindashboard />
              </AdminLayout>
            }
          />
          <Route
            path="/admindashboard/courses"
            element={
              <AdminLayout>
                <CourseList />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/courses/add_course"
            element={
              <AdminLayout>
                <AddCourse />
              </AdminLayout>
            }
          />
          <Route
            path="/studentDashboard/classes/videos"
            element={
              <AdminLayout>
                <StudentLayout />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/courses/course-form/:courseName"
            element={
              <AdminLayout>
                <AddCourse />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/courses/add_course/overview"
            element={
              <AdminLayout>
                <Coursedetails />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/manage-cohort"
            element={
              <AdminLayout>
                <ManageCohortsPage />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/updates"
            element={
              <AdminLayout>
                <Updates />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/updates/FAQs"
            element={
              <AdminLayout>
                <Faqs />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/updates/Testimonials"
            element={
              <AdminLayout>
                <Testimonials />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/updates/About us"
            element={
              <AdminLayout>
                <AboutUs1 />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/learn"
            element={
              <AdminLayout>
                <LearningPlatform />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/learn/videos/:coursename"
            element={
              <AdminLayout>
                <VideoList />
              </AdminLayout>
            }
          />

          <Route
            path="/admindashboard/learning/ba/videos"
            element={
              <AdminLayout>
                <VideoList />
              </AdminLayout>
            }
          />

          <Route path="/register/make-payment" element={<Payment1 />} />

          <Route
            path="/stripe-checkout"
            element={
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                {" "}
                <StripeCardForm />
              </Elements>
            }
          />
          <Route path="/payment-success/" element={<PaymentSuccess />} />
          <Route
            path="/stripe-payment-success/"
            element={<StripePaymentSuccess />}
          />
          <Route
            path="/second-payment-success/"
            element={<SecondPaymentSuccess />}
          />
          <Route path="/change-email" element={<ChangeEmail />} />
          <Route path="/change-email-success" element={<EmailSuccess />} />
          <Route path="/payment-failed/" element={<PaymentFailed />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
