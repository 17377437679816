import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiGrid42 } from "react-icons/ci";
import { FiBookOpen } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { MdOutlineTableChart } from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { logoutUser } from "../../services/authServices";

const TeachersSidebar = () => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Derive selectedButton from the current route
  const [selectedButton, setSelectedButton] = useState(() => {
    const path = location.pathname;
    if (path === "/teachersdashboard") return "dashboard";
    if (path === "/teachersdashboard/classes") return "classes";
    if (path === "/teachersdashboard/assignment") return "assignment";
    if (path === "/teachersdashboard/project") return "project";
    return "dashboard"; // Default to dashboard
  });
  useEffect(() => {
    // Update selectedButton whenever the route changes
    const path = location.pathname;
    if (path === "/teachersdashboard") setSelectedButton("dashboard");
    else if (path === "/teachersdashboard/classes")
      setSelectedButton("classes");
    else if (path === "/teachersdashboard/assignment")
      setSelectedButton("assignment");
    else if (path === "/teachersdashboard/project")
      setSelectedButton("project");
    else setSelectedButton("dashboard"); // Default to dashboard
  }, [location]);

  const handleLogout = async () => {
    const response = await logoutUser();
    if (response.data.message) {
      localStorage.clear("sawl_user");
      navigate("/login");
    } else {
      navigate("/studentdashboard");
    }
  };

  const handleButtonHover = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleButtonLeave = () => {
    setHoveredButton(null);
  };

  const sidebarItems = [
    {
      icon: CiGrid42,
      text: "Dashboard",
      path: "/teachersdashboard",
    },
    {
      icon: FiBookOpen,
      text: "Classes",
      path: "/teachersdashboard/classes",
    },
    {
      icon: CgNotes,
      text: "Assignments",
      path: "/teachersdashboard/assignment",
    },
    {
      icon: MdOutlineTableChart,
      text: "Project",
      path: "/teachersdashboard/project",
    },
  ];

  return (
    <div className="hidden md:flex flex-col px-6 pb-6 text-base border-r-2 border-[#A7AEB91A]  bg-white max-w-[240px] fixed left-0 h-screen">
      {sidebarItems.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          className={`flex gap-4 p-2.5 mt-2 text-sm rounded-md border-none ${
            selectedButton === item.text
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          }`}
          onMouseEnter={() => handleButtonHover(item.text)}
          onMouseLeave={handleButtonLeave}>
          <item.icon
            size={24}
            className={`shrink-0 w-6 aspect-square ${
              hoveredButton === item.text ? "text-white" : "text-sky-600"
            }`}
          />
          {item.text}
        </Link>
      ))}

      {/* Logout Button */}
      <div className="mt-20">
        <button
          className={`flex gap-4 p-2.5  rounded-md border-none ${
            hoveredButton === "logout"
              ? "bg-[#127ABB] text-white"
              : "hover:bg-[#127ABB] hover:text-white"
          }`}
          onClick={handleLogout}
          onMouseEnter={() => handleButtonHover("logout")}
          onMouseLeave={handleButtonLeave}>
          <RiLogoutBoxLine
            size={24}
            className={`shrink-0 w-6 aspect-square ${
              hoveredButton === "logout" ? "text-white" : "text-sky-600"
            }`}
          />
          Logout
        </button>
      </div>
    </div>
  );
};

export default TeachersSidebar;
