import React,{useState} from "react";
import ProjectCard from "./ProjectsCards";
import EmptyProjects from "./EmptyProject";
import { useDispatch, useSelector } from "react-redux";
import { selectprojects, SET_PROJECT } from "../../../redux/project/projectSlice";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const ProjectsPage = () => {
  // useRedirectLoggedOutUser("/login")
  const dispatch = useDispatch()

  // const projects = useSelector(selectprojects);
;
      const projects = [
        { id:1,
          title: "Smart Approaches We Learn 2",
          startDate: "03 May 2024",
          endDate: "24 May 2024",
          status: "Reviewed",
          commentCount: 1,
        },
        {
          id:2,
          title: "Smart Approaches We Learn 3",
          startDate: "03 May 2024",
          endDate: "24 May 2024",
          status: "Submitted",
          commentCount: 1,
        },
        {
          id:3,
          title: "Smart Approaches We Learn 4",
          startDate: "03 May 2024",
          endDate: "24 May 2024",
          status: "Not submitted",
        },
    ];

  const [selectedItem, setSelectedItem] = useState("All");

  const projectCards = useSelector(selectprojects)
  
    const handleProjectClick = (assignmentId) => {
      dispatch(SET_PROJECT(assignmentId));
    };

     const handleSelectChange = (e) => {
       setSelectedItem(e.target.value);
     };

     const filteredCards = Array.isArray(projectCards)
       ? selectedItem === "All"
         ? projectCards
         : projectCards.filter((card) => card.status === selectedItem)
       : []; 

    return (
      <>
        {projectCards.length === 0 ? (
          <EmptyProjects />
        ) : (
          <main className="flex flex-col px-8 pt-10 pb-20 py-3 h-full bg-neutral-50 max-md:px-5">
            <header className="flex gap-4 self-start font-medium whitespace-nowrap">
              <h1 className="my-auto text-xl text-teal-950">Projects</h1>
              <button className="flex gap-5 justify-between py-1.5 pr-2.5 pl-5 text-base text-white bg-sky-600 rounded-md">
                <select
                  value={selectedItem}
                  onChange={handleSelectChange}
                  className="bg-sky-600 h-full w-full border-none focus:outline-none appearance-none"
                  style={{
                    backgroundImage: `url('https://cdn.builder.io/api/v1/image/assets/TEMP/d143f3fcb8cf1c80eb7065ac8765191e77786e992e246e4fdd53e84d876c6808?apiKey=c4f2754934754c86a2ca35b496fd31f4&')`,
                    backgroundPosition: "right 0.5rem center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "1.25em",
                    paddingRight: "2rem",
                  }}>
                  <option value="All">All</option>
                  <option value="Not submitted">Not submitted</option>
                  <option value="submitted">Submitted</option>
                  <option value="reviewed">reviewed</option>
                </select>
              </button>
            </header>
            <div className="mt-8 max-md:max-w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {filteredCards.map((project, index) => (
                  <ProjectCard
                    {...project}
                    handleClick={() => handleProjectClick(project.id)}
                  />
                ))}
              </div>
            </div>
          </main>
        )}
      </>
    );
};

export default ProjectsPage;
