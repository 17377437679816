import axios from "axios";
import { BACKEND_URL } from "./url";


export const fetchCohorts = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/cohorts`);
    return response.data.cohorts;
  } catch (error) {
    console.error("Error fetching cohorts:", error);
    throw error; 
  }
};

// Fetch student statistics for a specific cohort
export const fetchStudentStatistics = async (cohortId) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/cohorts/${cohortId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching student statistics:", error);
    throw error; // Rethrow the error to handle it in the calling component if needed
  }
};
