import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSubmittedAssignments } from "../../../services/assignmentService";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import DocumentPreview from "../../DocumentPreview";
import { formatCreatedAt, formatDate } from "../../../utils/convertDate";
import CloudinaryFilePreview from "../../CloudinaryFilePreviewer";
import Thumbnail from "../../Thumbnails.js";
import { useSelector } from "react-redux";
import { selectAssignment } from "../../../redux/assignment/assignmentSlice.js";
import { convertToPngUrl } from "../../../utils/pdfToPng.js";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

function SubmittedAssignment() {
  // useRedirectLoggedOutUser("/login");

  const [submission, setSubmission] = useState([]);
  const selectedAssignment = useSelector(selectAssignment);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const submissionAttachment =
    submission?.attachments && JSON.parse(submission.attachments);
  const selectedAssignmentAttachment =
    selectedAssignment?.attachments &&
    JSON.parse(selectedAssignment.attachments);

  const params = useParams();

  const assignmentId = parseInt(params.id, 10);

  useEffect(() => {
    const fetchSubmittedAssignment = async () => {
      setIsLoading(true);
      try {
        const response = await fetchSubmittedAssignments(assignmentId);

        if (response.status >= 200 && response.status < 300) {
          setSubmission(response.data.submission);
        } else {
          console.error.error(
            response.data?.error ||
              "Failed to fetch assignment submission. Please try again."
          );
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment submission";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmittedAssignment();
  }, [assignmentId, params]);

  // Helper function to extract and format the attachment data
  const getAttachmentData = () => {
    try {
      return JSON.parse(submission?.attachments);
    } catch (error) {
      console.error("Error parsing attachments:", error);
      return null;
    }
  };

  // Helper function to extract and format the links data
  const getLinksData = () => {
    try {
      return JSON.parse(submission?.links);
    } catch (error) {
      console.error("Error parsing links:", error);
      return []; // Or return a default value if you have one
    }
  };

  return (
    <main className="px-12 py-14 bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col">
        <section className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow font-medium text-zinc-800 max-md:mt-10 max-md:max-w-full">
            {isLoading && (
              <Box sx={{ display: "flex w-full", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {submission && !isLoading && (
              <>
                <h1 className="text-xl text-teal-950 max-md:max-w-full">
                  {selectedAssignment.title}
                </h1>
                <div className="flex gap-5 justify-between px-8 py-5 mt-8 text-lg bg-white rounded-lg shadow-lg max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                  <div className="my-auto">Assignment details</div>
                  {toggleDetails ? (
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                      className="shrink-0 w-7 aspect-square"
                      onClick={() => setToggleDetails(!toggleDetails)}
                    />
                  ) : (
                    <img
                      alt=""
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                      className="shrink-0 w-7 aspect-square"
                      onClick={() => setToggleDetails(!toggleDetails)}
                    />
                  )}
                </div>
                {toggleDetails && (
                  <div className="flex flex-col justify-center items-start px-10 py-8 mt-10 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
                    <p className="mt-5 text-base">Assignment name</p>
                    <p className="mt-2.5 text-sm text-zinc-800">
                      {selectedAssignment.title}
                    </p>
                    <p className="self-stretch mt-6 text-base max-md:max-w-full">
                      Assignment instruction
                    </p>
                    <p className="self-stretch mt-2.5 text-sm leading-7 text-zinc-800 max-md:max-w-full">
                      {selectedAssignment.instructions}
                    </p>
                    <p className="mt-6 text-base">Link</p>
                    <a
                      className="mt-2.5 text-lg text-sky-600"
                      href={selectedAssignment.link}>
                      {selectedAssignment.link}
                    </a>
                    <div className="mt-6 text-base">File</div>
                    {selectedAssignmentAttachment ? (
                      <div className="flex gap-2.5 mt-2.5 text-zinc-800">
                        <div className="flex flex-col justify-center">
                          <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                            <Thumbnail
                              fileUrl={selectedAssignmentAttachment?.filePath}
                            />
                            {selectedAssignmentAttachment && (
                              <a
                                href={selectedAssignmentAttachment?.filePath}
                                target="_blank"
                                rel="noreferrer"
                                className="self-center mt-1.5 text-xs">
                                {selectedAssignmentAttachment?.fileName}
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col justify-center text-xs whitespace-nowrap">
                          <div className="flex flex-col px-2 w-[80px] py-2 rounded-md bg-slate-50">
                            <img
                              alt=""
                              loading="lazy"
                              srcSet={convertToPngUrl(
                                selectedAssignmentAttachment?.filePath
                              )}
                              className="self-center aspect-[1.23] w-[90px]"
                            />
                            <div className="items-center">
                              <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                                {selectedAssignmentAttachment?.fileName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="self-center mt-1.5 text-xs">
                        No attachment for this assignment
                      </p>
                    )}
                    <p className="mt-6 text-base">Assignment duration</p>
                    <p className="mt-2.5 text-lg text-zinc-800">
                      {`${formatDate(
                        selectedAssignment?.start_date
                      )} - ${formatDate(selectedAssignment?.due_date)}`}
                    </p>
                  </div>
                )}

                <div className="flex flex-col justify-center items-start px-10 py-8 mt-10 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
                  <h2 className="self-stretch text-lg font-medium max-md:max-w-full">
                    Your Submission
                  </h2>
                  <div className="mt-9 text-base text-neutral-500">
                    Document
                  </div>
                  <div className="flex gap-2.5 mt-2.5">
                    {getAttachmentData() && (
                      <>
                        <div className="flex flex-col justify-center rounded-none">
                          <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                            <div className="flex flex-col px-1.5 py-2 text-xs bg-white rounded-md">
                              {submissionAttachment && (
                                <Thumbnail
                                  fileUrl={submissionAttachment?.filePath}
                                />
                              )}
                            </div>
                            <div className="self-center mt-1.5 text-xs">
                              <a
                                href={submissionAttachment?.filePath}
                                target="_blank"
                                rel="noreferrer">
                                {getAttachmentData()?.fileName}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                          <div className="flex flex-col px-2 w-[80px] py-2 rounded-md bg-slate-50">
                            <img
                              alt=""
                              loading="lazy"
                              srcSet={convertToPngUrl(
                                submissionAttachment?.filePath
                              )}
                              className="self-center aspect-[1.23] w-[90px]"
                            />
                            <div className="items-center">
                              <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                                <a
                                  href={submissionAttachment?.filePath}
                                  target="_blank"
                                  rel="noreferrer">
                                  {submissionAttachment?.fileName}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
                    Note
                  </div>
                  <p className="self-stretch mt-2.5 text-lg leading-7 max-md:max-w-full">
                    {submission.submission?.notes}
                  </p>
                  <div className="mt-10 text-base text-neutral-500">Link</div>

                  <a
                    className="mt-2.5 text-lg text-sky-600"
                    target="_blank"
                    href={getLinksData()}
                    rel="noopener noreferrer">
                    {getLinksData()}
                  </a>
                </div>
              </>
            )}
          </div>
        </section>
        <aside className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col px-8 pt-8 pb-16 mx-auto mt-12 w-full bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
            <div className="text-base text-neutral-500">Status</div>
            <div className="flex gap-2.5 mt-2.5">
              <div className="px-5 py-2 text-base text-sky-600 whitespace-nowrap bg-sky-100 rounded-md">
                {submission?.status}
              </div>
              <div className="text-sm w-full text-zinc-800">{`on ${formatCreatedAt(
                submission?.created_at
              )}`}</div>
            </div>
            <hr className="shrink-0 mt-5 h-px border border-gray-200 border-solid" />
            <div className="self-start mt-5 text-base text-neutral-500">
              Grade
            </div>
            <div className="self-start mt-2 text-lg font-medium text-zinc-800">
              {submission?.grade ? submission.grade : "Pending"}
            </div>
          </div>
        </aside>
      </div>
    </main>
  );
}

export default SubmittedAssignment;
