import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "./url";

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const checkExistingStudent = async (email) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/check-student-existence`,
      email,
      { withCredentials: true }
    );
    // if (response.statusText === "OK") {
    //   toast.success("User Registered successfully");
    // }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
  
};

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/register_student`,
      userData,
      { withCredentials: true }
    );
    if (response.statusText === "OK") {
      toast.success("User Registered successfully");
    }
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      errors.forEach((error) => {
        toast.error(`${error.path}: ${error.msg}`);
      });
    } else {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  }
};

export const registerTutor = async (userData) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/register_tutor`,
      userData,
      { withCredentials: true }
    );
    if (response.statusText === "OK") {
      toast.success("User Registered successfully");
    }
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      errors.forEach((error) => {
        toast.error(`${error.path}: ${error.msg}`);
      });
    } else {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/login`, userData, {
      withCredentials: true,
    });
    if (response.statusText === "OK") {
      toast.success("Login Successful...");
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// Logout User
export const logoutUser = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/logout`, {
      withCredentials: true,
    });
    toast.success(response.data.message);
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

//send email to user
export const sendEmail = async (data) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/send-email`, data, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// Get User Profile
export const getUser = async (email) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/users/${email}`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message);
  }
};

// Get Login Status
export const getLoginStatus = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/users/loggedin`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const getAdminLoginStatus = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/users/admin/loggedin`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};

export const getTutorLoginStatus = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/users/teacher/loggedin`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    console.error(message);
  }
};
