import React, { useState, createContext, useContext, useEffect } from "react";
import AdminNav from "../AdminNav.js";
import AdminFooter from "./AdminFooter.js";
import AdminSidebar from "./AdminSideBar.js";
import SmallNav from "../TeachersDashboard/SmallNav.js";
import useRedirectLoggedOutAdmin from "../../customHook/useRedirectLoggedOutAdmin.js";
import { useDispatch } from "react-redux";
import { SET_LOGIN } from "../../redux/auth/authSlice.js";
import { getLoginStatus } from "../../services/authServices.js";
// 1. Create Context to Avoid Prop Drilling
const ActiveComponentContext = createContext(null);

function AdminLayout({ children }) {
  const [activeComponent, setActiveComponent] = useState("Dashboard");
  useRedirectLoggedOutAdmin("/login");

  const dispatch = useDispatch();

  useEffect(() => {
    async function loginStatus() {
      const status = await getLoginStatus();

      dispatch(SET_LOGIN(status));
    }
    loginStatus();
  }, [dispatch]);

  return (
    <ActiveComponentContext.Provider
      value={{ activeComponent, setActiveComponent }}>
      <div className="min-h-screen flex flex-col">
        {" "}
        {/* Full-height layout */}
        <AdminNav />
        <SmallNav />
        <main className="flex-grow flex bg-[#FAFAFA]">
          {" "}
          {/* Main content area takes up remaining space */}
          <div className="hidden md:block border-r border-[#A7AEB91A]">
            {" "}
            {/* Hide sidebar on small screens */}
            <AdminSidebar />
          </div>
          <div className="w-full px-4 mb-20 py-8 lg:px-10">
            {" "}
            {/* Content padding */}
            {children}
          </div>
        </main>
      </div>
    </ActiveComponentContext.Provider>
  );
}



export default AdminLayout;
