import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectProfile } from "../redux/auth/authSlice";
import { BACKEND_URL } from "../services/url";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatCreatedAt } from "../utils/convertDate";
import { useNavigate, useLocation } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { FaEdit } from "react-icons/fa";

const UploadPhoto = ({ closeModal }) => {
  const user = useSelector(selectUser);
  const profilePicture = useSelector(selectProfile);
  //const [profilePicture, setProfilePicture] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemovingPicture, setIsRemovingPicture]= useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("profilePicture", selectedImage);

    setIsLoading(true);

    try {
      const response = await axios.put(
        `${BACKEND_URL}/users/upload-profile-picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setPreviewUrl(null);
        const pathname = location.pathname;
        if (pathname.includes("studentDashboard")) {
          navigate("/studentDashboard");
        } else if (pathname.includes("teachersdashboard")) {
          navigate("/teachersdashboard");
        } else {
          navigate("/admindashboard");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

 
  const handleRemoveProfilePicture = async () => {
    setIsRemovingPicture(true);

    try {
      const response = await axios.delete(
        `${BACKEND_URL}/users/remove-profile-picture`,
        {
          withCredentials: true,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const pathname = location.pathname;
        if (pathname.includes("studentDashboard")) {
          navigate("/studentDashboard");
        } else if (pathname.includes("teachersdashboard")) {
          navigate("/teachersdashboard");
        } else {
          navigate("/admindashboard");
        }
      }
    } catch (error) {
      setIsRemovingPicture(false);
      console.error(error);
    }
  };
  return (
    <>
      {profilePicture ? (
        <div className="flex overflow-hidden flex-col pt-5 pr-5 pb-20 pl-10 font-medium bg-white rounded-lg max-w-[730px] max-md:pl-5">
          <div className="relative flex flex-col max-w-full text-xl text-teal-950 w-[445px]">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain shrink-0 self-end w-6 aspect-square"
              onClick={closeModal}
            />
            <div className="flex  max-w-full mx-auto">
              <p className="mt-4">Profile picture</p>
            </div>
            {previewUrl ? (
              <img
                alt=""
                loading="lazy"
                srcSet={previewUrl}
                className="shrink-0 my-auto mx-auto mt-8 rounded-full aspect-square w-[100px]"
              />
            ) : (
              <div className="relative flex flex-col items-center">
                <img
                  alt=""
                  loading="lazy"
                  srcSet={profilePicture}
                  onClick={handleImageClick}
                  className="shrink-0 my-auto mx-auto mt-8 rounded-full aspect-square w-[100px]"
                />
                <div className="absolute bottom-2 ">
                  {" "}
                  {/* Position the icon */}
                  <FaEdit
                    size={20}
                    className="text-sky-600 hover:text-sky-400"
                    onClick={handleImageClick}
                  />
                  {/* Example using Font Awesome */}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-wrap gap-5 mt-16 w-full text-lg max-md:mt-10 max-md:max-w-full">
            <button
              onClick={handleRemoveProfilePicture}
              className="flex flex-auto gap-2.5 justify-center items-center px-5 py-3 text-sky-600 rounded-md border border-sky-600 border-solid min-h-[45px]">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/067df2708d8b3019d7323e9a8c8ba1b8d63af81717d97cb8e284e9b854f1ce74?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                className="object-contain shrink-0  self-stretch my-auto w-6 aspect-square"
              />
              <p className="self-stretch my-auto text-sky-600">
                {isRemovingPicture ? (
                  <div className="inset-0 flex items-center justify-center">
                    <Circles
                      height="20"
                      width="20"
                      color="#0369a1"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Remove picture"
                )}
              </p>
            </button>
            <button
              onClick={handleUpload}
              disabled={!selectedImage}
              className="flex  flex-auto gap-2.5 justify-center items-center px-5 py-3 text-white bg-sky-600 rounded-md min-h-[45px]">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/863be8ec94b83fe4a7f79c872898780c5c7db8d0dfe257b7410d345dd4e230bb?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
              />
              <p className="self-stretch my-auto text-white">
                {isLoading ? (
                  <div className="inset-0 flex items-center justify-center">
                    <Circles
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Change picture"
                )}
              </p>
            </button>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </div>
        </div>
      ) : (
        <div className="flex overflow-hidden flex-col pt-5 pb-20 px-6 bg-white rounded-lg max-w-[730px] max-md:pl-5">
          <div className="flex flex-col max-w-full ">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain shrink-0 w-6 aspect-square self-end"
              onClick={closeModal}
            />
            <div className="flex max-w-full text-xl font-medium text-teal-950">
              <p className="mt-4 mx-auto">Profile picture</p>
            </div>
            <div
              className="flex flex-col mx-auto  mt-9 rounded-full bg-zinc-300 w-[100px]"
              onClick={handleImageClick}>
              {previewUrl ? (
                <img
                  src={previewUrl}
                  alt="Profile Preview"
                  className="object-contain rounded-full w-full "
                />
              ) : (
                <div className="relative flex flex-col items-center">
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/40abc576a54cdbf8598a6ee02278cdecd7711d971e4c1c7659f0baa943872702?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                    className="object-contain w-full aspect-square"
                  />
                  <div className="absolute bottom-2 ">
                    {" "}
                    <FaEdit
                      size={20}
                      className="text-white hover:text-sky-400"
                      onClick={handleImageClick}
                    />
           
                  </div>
                </div>
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </div>
          <button
            onClick={handleUpload}
            disabled={!selectedImage}
            className="flex flex-row hover:bg-sky-800 justify-center px-5 py-3 mt-16 text-lg font-medium text-white bg-sky-600 rounded-md min-h-[45px] max-md:mt-10">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/863be8ec94b83fe4a7f79c872898780c5c7db8d0dfe257b7410d345dd4e230bb?placeholderIfAbsent=true&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="object-contain shrink-0 self-center my-auto aspect-square mr-2" // Add 'mr-2' for right margin
            />
            <p className="self-stretch text-white">
              {" "}
              {isLoading ? (
                <div className="inset-0 flex items-center justify-center">
                  <Circles
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                "Upload picture"
              )}
            </p>
          </button>
        </div>
      )}
    </>
  );
};

export default UploadPhoto;
