import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { BACKEND_URL } from "../../../services/url";
import BarChart from "../../BarChart";

const ChartSection = () => {
  const [selectedOption, setSelectedOption] = useState("All");
  const [cohorts, setCohorts] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [initialCoursesData, setInitialCoursesData] = useState([]);

  useEffect(() => {
    const fetchCohorts = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/cohorts`);

        const {
          cohorts,
          AllstudentCounts,
          AllbusinessAnalysisStudentCount,
          AlldataanalyssisStudentCount,
        } = response.data;


        const allOption = { id: "All", start_date: null };
        const updatedCohorts = [allOption, ...cohorts];
        setCohorts(updatedCohorts);


        const initialData = [
          { name: "All", totalStudents: AllstudentCounts },
          { name: "Business analysis", totalStudents: AllbusinessAnalysisStudentCount },
          { name: "Data analysis", totalStudents: AlldataanalyssisStudentCount },
        ];
        setInitialCoursesData(initialData);
        setStudentsData(initialData);
      } catch (error) {
        console.error("Error fetching cohorts and student counts:", error);
      }
    };

    fetchCohorts();
  }, []);


  const fetchStudentStatistics = async (cohortId) => {
    try {
      if (cohortId === "All") {

        setStudentsData(initialCoursesData);
      } else {

        const response = await axios.get(`${BACKEND_URL}/cohorts/${cohortId}`);
        const { totalStudentsCount, businessAnalysisCount, dataAnalysisCount } = response.data;


        const updatedCoursesData = [
          { name: "All", totalStudents: totalStudentsCount || 0 },
          { name: "Business analysis", totalStudents: businessAnalysisCount || 0 },
          { name: "Data analysis", totalStudents: dataAnalysisCount || 0 },
        ];


        setStudentsData(updatedCoursesData);
      }
    } catch (error) {
      console.error("Error fetching student statistics:", error);
    }
  };


  const handleSelectChange = (event) => {
    const selectedCohortId = event.target.value;
    setSelectedOption(selectedCohortId);
    fetchStudentStatistics(selectedCohortId);
  };


  const transformedStudentsData = useMemo(
    () => [
      {
        name: "All",
        businessStudents: studentsData[1]?.totalStudents || 0,
        dataStudents: studentsData[2]?.totalStudents || 0,
      },
      {
        name: "Business analysis",
        businessStudents: studentsData[1]?.totalStudents || 0,
        dataStudents: 0,
      },
      {
        name: "Data analysis",
        businessStudents: 0,
        dataStudents: studentsData[2]?.totalStudents || 0,
      },
    ],
    [studentsData]
  );

  return (
    <div className="flex flex-col px-6 py-8 mt-10 rounded-md bg-white shadow-lg max-md:px-5 max-md:max-w-full">
      <div className="flex gap-4 self-start font-medium whitespace-nowrap">
        <div className="my-auto text-xl text-teal-950">Cohort</div>
        <div className="flex gap-5 justify-between py-1.5 pr-2.5 pl-5 text-base text-white bg-sky-600 rounded-md">
          {/* Cohort Selector Dropdown */}
          <select
            name="options"
            id="options"
            value={selectedOption}
            onChange={handleSelectChange}
            className="h-full w-full outline-none border-none bg-inherit"
          >
            {/* Include "All" option and other cohorts */}
            {cohorts.map((cohort, index) => (
              <option value={cohort.id} key={index}>
                {cohort.id === "All" ? "All Cohorts" : `${new Date(cohort.start_date).toLocaleString("default", { month: "long" })} ${new Date(cohort.start_date).getFullYear()}`}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Display the counts for each course */}
      <div className="flex gap-5 pb-4 mt-7 max-md:flex-wrap max-md:max-w-full">
        {studentsData.map((course, index) => (
          <div key={index} className="flex gap-2.5 px-5 py-4 bg-white rounded-lg shadow-md">
            <div className="flex flex-col">
              <div
                className={`self-start w-4 h-4 rounded-full ${course.name === "All" ? "bg-white" : ""
                  } ${course.name === "Business analysis" ? "bg-sky-600" : ""} ${course.name === "Data analysis" ? "bg-orange-700" : ""}`}
              />
              <p className="text-base text-neutral-500">
                {course.name === "All" ? "Total students" : course.name}
              </p>
              <p className="mt-2.5 text-2xl font-medium text-teal-950">{course.totalStudents}</p>
            </div>
          </div>
        ))}
      </div>
      {/* Pass the data to the BarChart component */}
      <BarChart students={transformedStudentsData} selectedOption={selectedOption} />
    </div>
  );
};

export default ChartSection;
