import React, { useState, useEffect } from "react";
import { fetchSubmittedAssignments } from "../../../services/assignmentService";
import { useParams } from "react-router-dom";
import { formatCreatedAt, formatDate } from "../../../utils/convertDate.js";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser.js";
import { selectAssignment } from "../../../redux/assignment/assignmentSlice";
import { useSelector } from "react-redux";
import CloudinaryFilePreview from "../../CloudinaryFilePreviewer.js";
import Thumbnail from "../../Thumbnails.js";
import { convertToPngUrl } from "../../../utils/pdfToPng.js";
import { Circles } from "react-loader-spinner";

const ReviewedAssignment = () => {
  // useRedirectLoggedOutUser("/login");
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const assignmentId = parseInt(params.id, 10);
  const [reviewed, setReviewed] = useState([]);
  const assignment = useSelector(selectAssignment);
  const [showReview, setShowReview] = useState(false);
  const assigmentAttachment =
    assignment?.attachments && JSON.parse(assignment.attachments);
  const reviewedAttachment =
    reviewed?.attachments && JSON.parse(reviewed.attachments);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [grade, setGrade] = useState(90);

  useEffect(() => {
    const fetchSubmittedAssignment = async () => {
      setIsLoading(true);
      try {
        const response = await fetchSubmittedAssignments(assignmentId);
        if (response.status >= 200 && response.status < 300) {
          setReviewed(response.data?.submission);
        } else {
          console.error(
            response.data?.error ||
              "Failed to fetch assignment submission. Please try again."
          );
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching assignment submission";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSubmittedAssignment();
  }, [assignmentId, params]);

  const handlePostComment = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="sm:flex font-roboto sm:flex-col-reverse px-12 py-14 cursor-pointer bg-neutral-50 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col font-medium text-zinc-800 max-md:mt-10 max-md:max-w-full">
            <div className="text-xl mb-4 text-teal-950 max-md:max-w-full">
              {assignment?.title}
            </div>
            <div
              className={`flex flex-col justify-between items-start px-8 py-5 w-full bg-white rounded-lg shadow-lg text-neutral-500 max-md:px-5 max-md:mt-10 max-md:max-w-full ${
                toggleDetails ? "h-auto" : "h-[100px"
              }`}>
              <div className="flex gap-5 justify-between self-stretch text-xl font-medium text-teal-950 max-md:flex-wrap max-md:max-w-full">
                <h2 className="my-auto">Assignment details</h2>
                {toggleDetails ? (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setToggleDetails(!toggleDetails)}
                  />
                ) : (
                  <img
                    alt=""
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                    className="shrink-0 w-7 aspect-square"
                    onClick={() => setToggleDetails(!toggleDetails)}
                  />
                )}
              </div>
              {toggleDetails && (
                <div className="mt-8">
                  <p className="mt-5 text-base">Assignment name</p>
                  <p className="mt-2.5 text-sm text-zinc-800">
                    {assignment.title}
                  </p>
                  <p className="self-stretch mt-6 text-base max-md:max-w-full">
                    Assignment instruction
                  </p>
                  <p className="self-stretch mt-2.5 text-sm leading-7 text-zinc-800 max-md:max-w-full">
                    {assignment.instructions}
                  </p>
                  <p className="mt-6 text-base">Link</p>
                  <a
                    className="mt-2.5 text-lg text-sky-600"
                    href={assignment.link}>
                    {assignment.link}
                  </a>
                  <div className="mt-6 text-base">File</div>
                  {assigmentAttachment ? (
                    <div className="flex gap-2.5 mt-2.5 text-zinc-800">
                      <div className="flex flex-col justify-center">
                        <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                          <Thumbnail fileUrl={assigmentAttachment?.filePath} />
                          {assigmentAttachment && (
                            <p className="self-center mt-1.5 text-xs">
                              <a
                                href={assigmentAttachment?.filePath}
                                target="_blank"
                                rel="noreferrer">
                                {assigmentAttachment?.fileName}
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                        <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                          <img
                            alt=""
                            loading="lazy"
                            srcSet={convertToPngUrl(
                              assigmentAttachment.filePath
                            )}
                            className="self-center aspect-[1.23] w-[124px]"
                          />
                          {assigmentAttachment && (
                            <p className="self-center mt-1.5 text-xs">
                              {assigmentAttachment?.fileName}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="self-center mt-1.5 text-xs">
                      No attachment for this project
                    </p>
                  )}
                  <p className="mt-6 text-base">Assignment duration</p>
                  <p className="mt-2.5 text-lg text-zinc-800">
                    {`${formatDate(assignment?.start_date)} - ${formatDate(
                      assignment?.due_date
                    )}`}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center items-start px-10 py-8 mt-10 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
              <div className="self-stretch text-lg font-medium max-md:max-w-full">
                Your Submission
              </div>
              <div className="mt-9 text-base text-neutral-500">Document</div>
              {reviewedAttachment ? (
                <div className="flex gap-2.5 mt-2.5">
                  <div className="flex flex-col">
                    <div className="flex flex-col px-2 py-2 rounded-md bg-slate-50">
                      <Thumbnail fileUrl={reviewedAttachment?.filePath} />

                      {reviewedAttachment && (
                        <p className="self-center mt-1.5 text-xs">
                          <a
                            href={reviewedAttachment?.filePath}
                            target="_blank"
                            rel="noreferrer">
                            {reviewedAttachment?.fileName}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="hidden lg:flex flex-col justify-center text-xs whitespace-nowrap">
                    <div className="flex flex-col px-2 w-[80px] py-2 rounded-md bg-slate-50">
                      <img
                        alt=""
                        loading="lazy"
                        srcSet={convertToPngUrl(reviewedAttachment?.filePath)}
                        className="self-center aspect-[1.23] w-[90px]"
                      />
                      <div className="items-center">
                        <p className="self-center text-[10px] overflow-hidden text-ellipsis whitespace-nowrap">
                          {reviewedAttachment?.fileName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="self-center mt-1.5 text-xs">
                  No attachment submitted for this project
                </p>
              )}

              <div className="self-stretch mt-10 text-base text-neutral-500 max-md:max-w-full">
                Note
              </div>
              <div className="self-stretch mt-2.5 text-lg leading-7 max-md:max-w-full">
                {reviewed?.notes}
              </div>
              <div className="mt-10 text-base text-neutral-500">Link</div>
              <div className="mt-2.5 text-lg text-sky-600">
                {reviewed.links?.replace(/^"|"$/g, "")}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full ${
            showReview ? "h-auto" : "h-[30px]"
          }`}>
          <div className="flex flex-col grow px-8 pt-8 pb-16 mx-auto w-full bg-white border border-solid border-zinc-400 max-md:px-5 max-md:mt-10">
            <div className="self-center text-xl font-medium text-teal-950">
              Reviewed
            </div>
            <div className="flex gap-5 justify-between px-5 py-2 mt-8 text-base bg-white rounded-lg border border-solid border-zinc-400 text-teal-950">
              <p className="my-auto">{reviewed?.studentName}</p>

              {showReview ? (
                <img
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e52e7a0a4bf3cfb5b511d453a0cc8de51182b6f562067ce2473061b779b62e4?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                  className="shrink-0 w-7 aspect-square"
                  onClick={() => setShowReview(!showReview)}
                />
              ) : (
                <img
                  alt=""
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/41f5e4b240ec9c1c66c390274ecfcb8d0fa7b45d1b18044b232dda2fa9bacd56?"
                  className="shrink-0 w-7 aspect-square"
                  onClick={() => setShowReview(!showReview)}
                />
              )}
            </div>
            <p className="mt-6 text-base font-medium text-zinc-800">Status</p>
            <div className="flex gap-2 mt-2.5">
              <p
                className={`justify-center px-3 py-2 text-sm whitespace-nowrap rounded-md ${
                  reviewed.status === "submitted"
                    ? "bg-sky-100 text-sky-600"
                    : "bg-green-100 text-green-600"
                }`}>
                {reviewed.status}
              </p>
              <p className="my-auto text-sm text-zinc-800">{`on ${formatCreatedAt(
                reviewed.created_at
              )}`}</p>
            </div>
            <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
            {showReview && (
              <>
                <p className="mt-5 text-base font-medium text-zinc-800">
                  Grade
                </p>
                <div className="flex gap-1.5 mt-2 text-lg whitespace-nowrap">
                  <input
                    className="justify-center w-full px-14 py-2 bg-white border border-solid border-zinc-400 text-neutral-500 max-md:px-5"
                    value={reviewed?.grade || grade}
                    onChange={(e) => setGrade(e.target.value)}
                  />
                  <p className="my-auto text-zinc-800">/</p>
                </div>
                <div className="shrink-0 mt-5 h-px bg-gray-200 border border-gray-200 border-solid" />
                <p className="mt-5 text-base text-neutral-500">Comment</p>
                <div className="mt-5 text-base text-neutral-500">
                  <span className="text-teal-950">You</span>
                  <br />
                  <span className="text-sm text-neutral-500">
                    {formatCreatedAt(reviewed.created_at)}
                  </span>
                </div>
                <p className="mt-2.5 text-base text-zinc-800">
                  {reviewed?.comment}
                </p>
                <div className="mt-5 text-base text-neutral-500">
                  <span className="text-red-700">{reviewed?.studentName}</span>
                  <br />
                  <span className="text-sm text-neutral-500"></span>
                </div>
                <div className="mt-2.5 text-base text-zinc-800">Thank you</div>
                <input
                  className="justify-center items-start px-5 py-4 mt-6 text-sm rounded-md border border-solid border-zinc-400 text-neutral-500"
                  placeholder="Type your comment..."
                />
                <button
                  onClick={handlePostComment}
                  className="justify-center text-cenetr hover:bg-sky-800 items-center px-5 py-2 mt-12 text-lg text-white whitespace-nowrap bg-sky-600 rounded-md max-md:mt-10">
                  {isLoading ? (
                    <div className="inset-0 flex items-center justify-center">
                      <Circles
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Post"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewedAssignment;
