import React, { useState } from "react";
import { Link } from "react-router-dom";
import EmptyAssignment from "./EmptyAssignment"
import { useSelector } from "react-redux";
import { selectAssignments } from "../../../redux/assignment/assignmentSlice";
import { formatDate } from "../../../utils/convertDate";
import { SET_ASSIGNMENT } from "../../../redux/assignment/assignmentSlice";
import { useDispatch } from "react-redux";
import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";

const StudentAssignment = () => {
  // useRedirectLoggedOutUser("/login")
  const [selectedItem, setSelectedItem] = useState("All");
  const dispatch = useDispatch()

  const handleSelectChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const assignmentCards = useSelector(selectAssignments)
    const handleAssignmentClick = (assignmentId) => {
     dispatch(SET_ASSIGNMENT(assignmentId));
   };

  // const assignmentCards = [
  //   {
  //     id: 1,
  //     status: "Reviewed",
  //     name: "User flow diagram",
  //     startDate: "08 May 2024",
  //     dueDate: "11 May 2024",
  //     link: "/studentdashboard/assignment/reviewed",
  //   },

  //   {
  //     id: 2,
  //     status: "Not submitted",
  //     name: "User flow diagram",
  //     startDate: "08 May 2024",
  //     dueDate: "11 May 2024",
  //     link: "/studentdashboard/assignment/reviewed",
  //   },
  //    {
  //     id: 3,
  //     status: "Not submitted",
  //     name: "User flow diagram",
  //     startDate: "08 May 2024",
  //     dueDate: "11 May 2024",
  //     link: "/studentdashboard/assignment/reviewed",
  //   },

  //   {
  //     id: 4,
  //     status: "Submitted",
  //     name: "SRS documentation",
  //     startDate: "16 May 2024",
  //     dueDate: "20 May 2024",
  //     link: "/studentdashboard/assignment/submitted",
  //   },
  //   {
  //     id: 5,
  //     status: "Not submitted",
  //     name: "User story acceptance criteria",
  //     startDate: "25 May 2024",
  //     dueDate: "29 May 2024",
  //     link: "/studentdashboard/assignment/not_submitted",
  //   },
  // ];

  const filteredCards =
    selectedItem === "All"
      ? assignmentCards
      : assignmentCards.filter((card) => card.status === selectedItem);
  
   const statusClasses = {
     reviewed: "text-green-800 bg-green-100",
     submitted: "text-sky-600 bg-sky-100",
     "Not submitted": "bg-gray-200",
   };

  return (
    <>
      {assignmentCards.length === 0 ? (
        <EmptyAssignment />
      ) : (
        <div className="flex flex-col px-8 pt-10 pb-[40px] py-6 bg-neutral-50 max-md:px-5">
          <div className="flex gap-4 self-start font-medium whitespace-nowrap">
            <h2 className="my-auto text-xl text-teal-950">Assignments</h2>
            <div className="flex py-1.5 pr-2.5 pl-5 text-base text-white bg-sky-600 rounded-md">
              <select
                value={selectedItem}
                onChange={handleSelectChange}
                className="bg-sky-600 h-full w-full border-none focus:outline-none appearance-none"
                style={{
                  backgroundImage: `url('https://cdn.builder.io/api/v1/image/assets/TEMP/d143f3fcb8cf1c80eb7065ac8765191e77786e992e246e4fdd53e84d876c6808?apiKey=c4f2754934754c86a2ca35b496fd31f4&')`,
                  backgroundPosition: "right 0.5rem center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "1.25em",
                  paddingRight: "2rem",
                }}>
                <option value="All">All</option>
                <option value="submitted">submitted</option>
                <option value="reviewed">reviewed</option>
                <option value="Not submitted">Not submitted</option>
              </select>
            </div>
          </div>
          <div className="mt-8 max-md:max-w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {Array.isArray(filteredCards) &&
                filteredCards.map((card) => (
                  <Link
                    key={card.id}
                    onClick={() => handleAssignmentClick(card.id)}
                    to={`/studentdashboard/assignment/${card.status}/${card.id}`}
                    className="w-full flex grow m-h-[250px]">
                    <div className="flex flex-col grow px-5 pt-5 pb-6 mx-auto w-full bg-white rounded-md shadow-lg max-md:mt-4">
                      <div className="flex gap-5 justify-between w-full whitespace-nowrap">
                        <p
                          className={`justify-center px-5 py-1.5 text-sm rounded-md  ${
                            statusClasses[card.status]
                          }`}>
                          {card.status}
                        </p>
                        {card.comment ? (
                          <>
                            <div className="flex gap-1.5 justify-center my-auto text-base text-center text-neutral-500">
                              <img
                                alt=""
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/be74bf9c9e2214ee9efa84423eb053c824da7a838c8e6176e2011f48a6516998?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                                className="shrink-0 w-5 aspect-square"
                              />
                              <div>1</div>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <p className="mt-5 text-base font-medium text-zinc-800">
                        {card.title}
                      </p>
                      <div className="flex gap-5 justify-between mt-5 text-sm">
                        <div className="flex flex-col justify-center">
                          <p className="text-neutral-500">Start date:</p>
                          <p className="mt-1.5 text-zinc-800">
                            {formatDate(card.start_date)}
                          </p>
                        </div>
                        <div className="flex flex-col justify-center">
                          <p className="text-neutral-500">Due on:</p>
                          <p className="mt-1.5 text-zinc-800">
                            {formatDate(card.due_date)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentAssignment;
