import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux"
import { selectCourse, selectPaymentDetails, selectUser, selectProfile } from "../../redux/auth/authSlice";
import UserDetails from "./UserDetails";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../services/authServices";
import UploadProfilePicture from "./UploadProfilePicture"
import Modal from 'react-modal';
import UploadPhoto from "../UploadPhoto"
import CustomModal from "../StudentsDashboard/Modal";
import { FaEdit } from "react-icons/fa";

const StudentProfile = () => {
  const user = useSelector(selectUser)
  const profilePicture = useSelector(selectProfile)
  const course = useSelector(selectCourse)
  const paymentDetails = useSelector(selectPaymentDetails)
  const [isModalOpen, setIsModalOpen] = useState(false); 

  const handlePayment = () => {
    
  }

   const openModal = () => {
     setIsModalOpen(true);
   };

   const closeModal = () => {
     setIsModalOpen(false);
   };

const navigate = useNavigate()
  return (
    <div className="flex flex-col flex-wrap px-10 py-12 bg-neutral-50 max-md:px-5">
      <div className="px-12 py-5 bg-white rounded-lg shadow-lg max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="relative flex flex-col w-[35%] max-md:ml-0 max-md:w-full">
            {!profilePicture ? (
                <img
                  // onClick={() => navigate("/teachersdashboard/profile/upload")}
                  onClick={openModal}
                  alt=""
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9e27f6fa3f8b9bffa9bb42352016620c24e89dfc6865e92e9dcd89a7815da5ad?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
                  className="shrink-0 max-w-full aspect-square w-[100px] max-md:mt-9"
                />
            ) : (
              <div className="relative">
                <img
                  // onClick={() => navigate("/teachersdashboard/profile/upload")}
                  onClick={openModal}
                  alt=""
                  loading="lazy"
                  srcSet={profilePicture}
                  className="shrink-0 my-auto rounded-full aspect-square w-[70px]"
                />
                <div className="absolute bottom-2 left-2">
                  {" "}
                  {/* Position the icon */}
                  <FaEdit
                    size={20}
                    className="text-sky-600 hover:text-sky-400"
                    onClick={openModal}
                  />
                  {/* Example using Font Awesome */}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col ml-5 w-[65%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto text-base text-zinc-800 max-md:mt-10">
              <p className="text-xl font-medium text-teal-950">{`${user.firstName} ${user.lastName}`}</p>
              <p className="mt-2.5">{` ${course} student`}</p>
              <p className="mt-2">Feb ‘24 Cohort</p>
            </div>
          </div>
        </div>
      </div>
      <UserDetails
        user={user}
        course={course}
        paymentDetails={paymentDetails}
        handlePayment={handlePayment}
      />

      <CustomModal isOpen={isModalOpen}>
        <UploadPhoto closeModal={() => setIsModalOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default StudentProfile;
