import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectUser,
  SET_LOGIN,
  selectProfile,
} from "../../redux/auth/authSlice.js";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../services/authServices.js";
import TutorSearchComponent from "./TutorSearch.js";

const StudentNav = () => {
  const [openProfileModal, setPenProfileModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const profilePicture = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [isNavVisible, setIsNavVisible] = useState(true);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollThreshold = 1200;

      setIsNavVisible(
        (prevScrollPos > currentScrollPos &&
          currentScrollPos > scrollThreshold) ||
          currentScrollPos < scrollThreshold
      );

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogout = async () => {
    await logoutUser();
    dispatch(SET_LOGIN(false));
    navigate("/login");
  };

  return (
    <div
      className={`hidden  
      md:flex gap-5 justify-between border-b border-[#A7AEB91A] relative shadow-md px-10 py-3.5 bg-white max-md:flex-wrap max-md:px-5 z-50 ${
        isNavVisible ? "sticky top-0 inset-0" : ""
      } transition-transform duration-300`}>
      <div className="flex gap-5 justify-between text-xs font-medium text-zinc-400 max-md:flex-wrap max-md:max-w-full">
        <a href="/">
          <img
            alt=""
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd4fa30be53243f976df8bd0b4487d2a7491b800fb3be5be6d0934fcd2d3e25d?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 max-w-full aspect-[2.27] w-[120px]"
          />
        </a>
        <div className="hidden md:flex gap-2.5 p-2.5 my-auto  rounded-md border border-solid border-zinc-400 max-md:flex-wrap">
          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8a5f22ac6c370f650c41e86f5d0b753a71218dc7233af96e62dc2229ecbd150b?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 aspect-square fill-zinc-400 w-[15px]"
          />
          <TutorSearchComponent />
        </div>
      </div>

      <div className="flex gap-5 justify-between self-start">
        <img
          alt=""
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/95ef8cbc24032733327243044841fc63983494cb8255f81a7391d25a4cec20a6?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
          className="shrink-0 my-auto aspect-square w-[25px]"
        />
        <div className="flex gap-2.5">
          {!profilePicture ? (
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/42df70c92e08d689b0c78e02095eb3a36617dc5077d6afe06301d0920a7e87a7?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 aspect-square w-[50px]"
            />
          ) : (
            <img
              alt=""
              loading="lazy"
              src={profilePicture}
              className="shrink-0 aspect-square w-[50px] my-auto rounded-full "
            />
          )}

          <img
            alt=""
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8308025baca7728bac7348a564267bd03ebc779b2f34eb72ceda0e5e436a28a6?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
            className="shrink-0 my-auto w-1 aspect-[0.22]"
            onClick={handleOpenMenu}
          />
        </div>
      </div>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem onClick={() => setPenProfileModal(true)}>
          <div className="flex gap-2.5">
            {!profilePicture ? (
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/42df70c92e08d689b0c78e02095eb3a36617dc5077d6afe06301d0920a7e87a7?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
                className="shrink-0 aspect-square w-[50px]"
              />
            ) : (
              <img
                alt=""
                loading="lazy"
                src={profilePicture}
                className="shrink-0 aspect-square w-[50px] my-auto rounded-full "
              />
            )}
            <div className="flex flex-col my-auto">
              <div className="text-lg font-medium text-teal-950">
                {user.username}
              </div>
              <div className="mt-1 text-sm text-neutral-500">{user.email}</div>
            </div>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/teachersdashboard/profile");
            handleCloseMenu();
          }}>
          <div className="flex gap-3.5 mt-5 text-base whitespace-nowrap text-zinc-800">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/30868a278e3645d33fb862a023ec3abbe55307ddbc0dd24187acd225ba93f881?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 w-5 aspect-square"
            />
            <p>Profile</p>
          </div>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <div className="flex gap-3.5 mt-5 text-base whitespace-nowrap text-zinc-800">
            <img
              alt=""
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/02dfc6d1729475476388dacd5733d237ada32ddfd1702f58d2c955f98bd97a99?apiKey=c4f2754934754c86a2ca35b496fd31f4&&apiKey=c4f2754934754c86a2ca35b496fd31f4"
              className="shrink-0 w-5 aspect-square"
            />
            <p>Logout</p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default StudentNav;
